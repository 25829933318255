import React, {
  Suspense,
} from 'react'

import PreferencesApp from 'components/PreferencesApp'

import render from '../render'


export default function createPreferenceAppLauncher({ consentConfig, website }) {
  return () => {
    render(
      <Suspense
        fallback={ null }
      >
        <PreferencesApp
          consentConfig={ consentConfig }
          website={ website }
        />
      </Suspense>
    )
  }
}
