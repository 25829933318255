import getConfigFromURL from './getConfigFromURL'
import getLegacyConfig from './getLegacyConfig'

// N.B.: We're assuming that the dev proxy is up and running on your
// development host. It'd be pretty hard to do any development without
// it, so this should be a safe bet.
const DEV_API_HOST = 'https://localhost.termly.io:8000'

// Captures the websiteUUID
const DYNAMIC_URL_REGEX = new RegExp('^/resource-blocker/([^/]+)')


export default function getConfig({ dataset, id, src }) {
  const srcURL = new URL(src)

  const apiBase = ( process.env.NODE_ENV === 'development' )
    ? DEV_API_HOST
    : srcURL.origin

  const config = _getConfig({
    dataset,
    id,
    src: srcURL,
  })

  return {
    apiBase,
    ...config,
  }
}

function _getConfig({ dataset, id, src }) {
  const matches = src.pathname.match(DYNAMIC_URL_REGEX)

  if ( matches ) {
    return getConfigFromURL({
      src,
      websiteUUID: matches[1],
    })
  }

  return getLegacyConfig({
    dataset,
    id,
  })
}
