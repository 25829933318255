import CurrentRegionSettings from 'constants/current-region-settings'
import { UNCLASSIFIED } from 'constants/categories'

import CookieConsents from 'utils/cookieConsents'

import BlockingList from '../constants/blockingList'

import makeGetCategoryForURL from './makeGetCategoryForURL'

const getCategoryForURL = makeGetCategoryForURL({
  ...BlockingList,
  ...window.TERMLY_CUSTOM_BLOCKING_MAP,
})

/**
 * @param {String} hostname
 *
 * @returns {String} the category corresponding to the supplied string
 */
export {
  getCategoryForURL,
}

/**
 * A URL is considered blockable (i.e., it should be blocked) if
 * the category to which it maps is not present in the set of
 * categories to which the customer has given his consent.
 *
 * @param {URL} url
 * @param {String} [categoryOverride]
 *
 * @returns {Boolean}
 */
export function isBlockable(url, categoryOverride) {
  const category = categoryOverride || getCategoryForURL(url) || UNCLASSIFIED

  const categoryList = category.split(',')

  // This is an "optimization"...or rather a correction of legacy
  // behavior.  Because the legacy resource-blocker (/embed.min.js)
  // had to make an async call to get the website and client info, it
  // was impossible to know whether we the current site is `opt_in` or
  // `opt_out` when setting up our autoBlocker overrides. So, we had
  // to be paranoid and assume that in the absense of user-granted
  // consents, everything gets blocked. Only after we get our configs
  // from the backend could we then say "Okay, unblock
  // everything--this site is `opt_out`."
  //
  // However, with the advent of configs embedded in the JS bundle
  // (via /resource-blocker/UUID), we no longer have to make do this
  // "turn it all off then back on again" switcheroo. We know up front
  // whether we're `opt_in` or `opt_out`. This makes it possible for us
  // to make smarter decisions (like the one you see below).
  if ( CurrentRegionSettings.isOptOut() ) {
    return CookieConsents.isAnyDeclined(categoryList)
  }

  return !CookieConsents.isAnyConsented(categoryList)
}
