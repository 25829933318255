import React from 'react'
import ReactDOM from 'react-dom'

import { ReactQueryClientProvider } from '@termly_web/common'

import CookieConsents from 'utils/cookieConsents'
import enableConsentScripts from 'utils/enableConsentScripts'
import generateID from 'utils/generateID'

import BlockedContent from 'components/BlockedContent'
import ConsentConfigProvider from 'components/ConsentConfigProvider'
import ResourceBlockerIntlProvider from 'components/ResourceBlockerIntlProvider'
import ThemeProvider from 'components/ThemeProvider'

const MIN_IFRAME_WIDTH = 196
const MIN_IFRAME_HEIGHT = 134

const THEME_PROVIDER_STYLE = {
  height: '100%',
}

const iframeID = generateID('termly-iframe')


export default function replaceIframes({ consentConfig, supportedLocales }) {
  const iframes = document.querySelectorAll('iframe')

  // The crawler splash engine (in termly/cookie_scanner)
  // will choke on NodeList#forEach because it is using an ancient version
  // of WebKit under the covers. I've never been able to get the scanner up
  // and running properly on my machine, so I can't tell if there's any
  // cleaner construct that will work e.g., `for...of` or even
  // `Array.from(iframes).forEach(...)`
  for ( let i = 0; i < iframes.length; ++i ) {
    const iframe = iframes[i]

    if ( !iframe.dataset.categories ) {
      continue
    }

    const categories = iframe.dataset.categories.split(',')
    if ( CookieConsents.isAnyConsented(categories) ) {
      continue
    }

    // If the space allocated for an iframe is big enough, we'll inject
    // an "Enable this content?" banner in into the DOM, right before
    // the iframe. But we don't do this for tiny iframes because we don't
    // want to disrupt the flow of the page.
    if ( isBigEnoughToReplace(iframe) ) {
      const replacement = createReplacement({
        consentConfig,
        referenceNode: iframe,
        supportedLocales,
      })

      iframe.parentNode.insertBefore(replacement, iframe)
    }

    // Regardless of whether we're able to show a "enable this
    // content?" banner, we need to hide the iframe because no consent
    // has been given for it.
    iframe.dataset.display = iframe.style.display || 'block'

    if ( !iframe.dataset.src ) {
      iframe.dataset.src = iframe.getAttribute('src')
      iframe.removeAttribute('src')
    }

    iframe.style.display = 'none'
  }
}


function isBigEnoughToReplace(referenceNode) {
  return (
    referenceNode.offsetWidth >= MIN_IFRAME_WIDTH
      && referenceNode.offsetHeight >= MIN_IFRAME_HEIGHT
  )
}

function createReplacement({ consentConfig, referenceNode, supportedLocales }) {
  const dom = document.createElement('div')

  dom.style.cssText = referenceNode.style.cssText
  dom.style.width = `${ referenceNode.offsetWidth }px`
  dom.style.height = `${ referenceNode.offsetHeight }px`
  dom.style.setProperty('--termly-blocked-content-max-height', `${ referenceNode.height }px`)
  dom.style.setProperty('--termly-blocked-content-max-width', `${ referenceNode.width }px`)
  dom.style.display = 'flex'
  dom.style.flexDirection = 'column'
  dom.style.justifyContent = 'center'
  dom.style.overflow = 'hidden'
  dom.className = 'termly-blocked-content t-blocked-content-container'
  dom.dataset.blockedCategories = referenceNode.dataset.categories

  const id = iframeID.next().value
  dom.dataset.blockedIframeId = id
  referenceNode.dataset.termlyIframeId = id

  const handleAcceptClick = () => {
    const categories = referenceNode.dataset.categories.split(',')

    enableConsentScripts(categories, {
      isTemporary: true,
    })
  }

  // We wrap the invocation of displayPreferenceModal() in a function
  // because it most likely has not yet been created when this
  // component is rendered...it's a mess.
  const handleManagePrefsClick = () => {
    if ( !window.displayPreferenceModal ) {
      console.error('displayPreferenceModal() is not defined')
      return
    }

    window.displayPreferenceModal()
  }

  const site = getSiteNameFromElem(referenceNode)

  ReactDOM.render(
    <ReactQueryClientProvider>
      <ResourceBlockerIntlProvider
        supportedLocales={ supportedLocales }
      >
        <ConsentConfigProvider
          consentConfig={ consentConfig }
        >
          <ThemeProvider
            style={ THEME_PROVIDER_STYLE }
          >
            <BlockedContent
              handleAcceptClick={ handleAcceptClick }
              handleManagePrefsClick={ handleManagePrefsClick }
              site={ site }
            />
          </ThemeProvider>
        </ConsentConfigProvider>
      </ResourceBlockerIntlProvider>
    </ReactQueryClientProvider>,
    dom)

  return dom
}

function getSiteNameFromElem(elem) {
  const src = elem.dataset.src || elem.src

  // The second argument (the base) is necessary to prevent barfing
  // when we encounter an incomplete URL such as '//foo.com'
  return new URL(src, window.location).hostname
}
