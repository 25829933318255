import isElementManuallyBlocked from 'utils/isElementManuallyBlocked'

const DEEP_CLONE = true


export function unblockAll() {
  querySelectorAll('[data-categories]')
    .filter(isElementManuallyBlocked)
    .forEach(unblockAndReplaceNode)
}

/**
 * @param {String} urlSubstring
 */
export function unblockByURLSubstring(urlSubstring) {
  const selector = ['data-src', 'data-href']
    .map((attr) => `[${ attr }*='${ urlSubstring }']`)
    .join(',')

  querySelectorAll(selector)
    .forEach(unblockAndReplaceNode)
}

/**
 * @param {Object} args
 * @param {String[]} args.categories
 * @param {Object} [args.config]
 */
export function unblockByCategory(categories, config) {
  const categoriesToUnblock = new Set(categories)

  querySelectorAll('[data-categories]')
    .filter(isElementManuallyBlocked)
    .filter((node) => {
      const nodeCategories = node.dataset.categories.split(',')

      return nodeCategories.some((category) => categoriesToUnblock.has(category))
    })
    .forEach((node) => {
      unblockAndReplaceNode(node, config)
    })
}

function unblockNode(blockedNode, config) {
  // Deep cloning is necessary particularly for inlined scripts...otherwise
  // we will replace the tag but lose the JavaScript code within it.
  const node = blockedNode.cloneNode(DEEP_CLONE)
  const src = blockedNode.getAttribute('data-src')

  // Sometimes nodes have an onload handler that needs to be executed
  // to initialize or otherwise execute the code contained therein. If
  // we don't copy the handler (cloneNode() will not do it for us),
  // the node will appear to be blocked, even though the DOM shows
  // that it is not.
  node.onload = blockedNode.onload

  // This is necessary when the user wants to temporarily enable blocked content
  // without storing a consent.
  if ( config?.isTemporary ) {
    node.setAttribute('data-autoblock-ignore', 1)
  }

  if ( node.tagName === 'SCRIPT' ) {
    node.setAttribute('type', 'text/javascript')
  } else if ( node.tagName === 'IFRAME' ) {
    const iframeID = blockedNode.dataset.termlyIframeId
    const blockedIframeNotice = blockedNode.parentNode.querySelector(`[data-blocked-iframe-id='${ iframeID }']`)

    blockedIframeNotice?.remove()

    if ( node.dataset.display ) {
      node.style.display = node.dataset.display
      node.removeAttribute('data-display')
    } else {
      node.style.display = null
    }
  }

  if ( node.hasAttribute('data-src') ) {
    node.setAttribute('src', src)
    node.removeAttribute('data-src')
  }

  if ( node.hasAttribute('data-href') ) {
    node.setAttribute('href', blockedNode.getAttribute('data-href'))
    node.removeAttribute('data-href')
  }

  node.removeAttribute('data-autoblocked')

  return node
}

function querySelectorAll(query) {
  return Array.from(document.querySelectorAll(query))
}

function unblockAndReplaceNode(blockedNode, config) {
  const node = unblockNode(blockedNode, config)

  blockedNode.replaceWith(node)
}
