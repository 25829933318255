// TODO?: Move this into utils/cookieConsents.js

import axios from 'axios'

import CookieConsents from 'utils/cookieConsents'
import URL from 'utils/url'

import {
  setCookie,
  getCookie,
  uuidCookie,
} from 'utils/cookie'

import GTM from 'utils/gtm'
import enableConsentScripts from 'utils/enableConsentScripts'

import needsFullPageReload from './needsFullPageReload'
import triggerGetUpdatedCookieWhitelistByTermly from './triggerGetUpdatedCookieWhitelistByTermly'


export default function updateCookieConsent(args) {
  const {
    acString,
    cookiePolicy,
    eventType,
    consentMode,
    cookies = window.termlyCookies,
    cookieConsent,
    tcString,
  } = args

  if ( !cookiePolicy ) {
    return () => {
      console.info('Not saving Termly consents: No cookie policy found.')
    }
  }

  const needsReload = needsFullPageReload({
    cookieConsent,
    consentMode,
  })

  return function saveCookieConsent() {
    const userCookie = getCookie(uuidCookie)

    axios
      .post(URL.saveCookieConsent(), {
        ac_string: acString,
        event_type: eventType,
        document_version_id: cookiePolicy.published_document_version_id,
        tc_string: tcString,
        template_version_id: cookiePolicy.accepted_template_version,
        ...cookieConsent,
        ...(!!userCookie && { uuid: userCookie }),
      })
      .then(({ data }) => {
        const uuid = data.visitor.uuid

        // TODO: why are we invoking this again?
        if ( !getCookie(uuidCookie) ) {
          setCookie(uuidCookie, uuid)
        }

        CookieConsents.setConsents(data)
        GTM.send(cookieConsent)

        triggerGetUpdatedCookieWhitelistByTermly({
          consentMode,
          consentState: CookieConsents.getConsentSettings(),
          cookieConsent,
          cookies,
          visitorId: uuid,
        })

        if ( needsReload ) {
          window.location.reload()
        } else {
          const consentedCategories = Object.entries(cookieConsent)
            .filter(([category, isConsented]) => isConsented)
            .map(([category, isConsented]) => category)

          enableConsentScripts(consentedCategories)
        }
      })
  }
}
