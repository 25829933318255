import {
  isScript,
  isIframe,
} from './tagUtils'

import makeCreateAttribute from './makeCreateAttribute'
import makeSetAttribute from './makeSetAttribute'


/**
 * @param {Function} nativeCreateElement
 *
 * @returns {Function}
 */
export default function makeCreateElement(nativeCreateElement) {
  /**
   * An override for `document.createElement()` that overrides the
   * setters for the `type` and `src` attributes, with the goal being
   * to thwart efforts to load prohibited resources.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/createElement}
   *
   * @param {String} tagName
   * @param {Object} [options]
   *
   * @returns {Element} the new element
   */
  return (tagName, options) => {
    const element = nativeCreateElement.bind(document)(tagName, options)

    if ( !isScript(element) && !isIframe(element) ) {
      return element
    }

    // If someone assigns a custom `data-categories` to a script and that script
    // creates elements, we want the created elements to have the same categories
    // so they'll be treated the same way. It's confusing to have an IG script,
    // to have a data-categories="advertising" and its children created as
    // the default "social_networking"
    //
    // I'm not sure why, but in Cypress tests, at least, there are times when
    // document.currentScript is not defined.
    if ( document.currentScript && document.currentScript.dataset.categories ) {
      element.dataset.categories = document.currentScript.dataset.categories
    }

    element.setAttribute = makeSetAttribute(element)

    return Object.defineProperties(element, {
      type: makeCreateAttribute('type'),
      src: makeCreateAttribute('src'),
    })
  }
}
