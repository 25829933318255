const STRIP_LOCALE_REGEXP = /-.*$/


/**
 * Returns the best-matching preferred locale from the list of supported
 * (available) languages. Matching is case-insensitive, and exact matches
 * are preferred over partial matches.
 *
 * @param {Object} args
 * @param {String[]} args.supportedLocales - the reference list of language
 *   codes from which the user is allowed to choose
 * @param {String[]} args.preferredLocales - the list of language codes that
 *   the user says he wants, sorted in order of decreasing preference (i.e.,
 *   the first language is the one he really wants, the last is the least-
 *   desired)
 *
 * @returns {String,undefined}
 */
export default function selectLocale({ supportedLocales, preferredLocales }) {
  if ( !supportedLocales?.length || !preferredLocales?.length ) {
    return
  }

  const supported = new Set(supportedLocales.map(normalize))
  const preferred = preferredLocales.map(normalize)

  const exactMatch = findMatch(supported, preferred)

  if ( exactMatch ) {
    return exactMatch
  }

  const partialLocales = preferred
    .filter((locale) => locale.includes('-'))
    .map(stripLocale)

  return findMatch(supported, partialLocales)
}

function findMatch(supported, preferred) {
  return preferred.find((locale) => {
    return supported.has(locale)
  })
}

function normalize(string) {
  return string.toLowerCase()
}

function stripLocale(code) {
  return code.replace(STRIP_LOCALE_REGEXP, '')
}
